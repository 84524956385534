import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { RingLoader } from "react-spinners";
import { getCookie, setCookie } from "cookies-next";
import axios from "axios";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { UserLoginInfo, useUserStore } from "@/store/user.store";
import { nanoid } from "nanoid";
import { BodyText } from "@/components/BodyText";
import Link from "next/link";

type Provider = "google" | "facebook";

type FormValues = {
  username?: string;
  password?: string;
  remember?: boolean;
  onSubmit: (data?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

function GoogleSvg() {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5410"
      width="26"
      height="26"
    >
      <path
        d="M213.561727 512.080958a296.85528 296.85528 0 0 1 15.679539-94.717218L53.726422 286.071597a504.145191 504.145191 0 0 0 0 451.73073l175.386848-131.516137a295.991305 295.991305 0 0 1-15.359549-94.26923"
        fill="#FBBC05"
        p-id="5411"
      ></path>
      <path
        d="M521.87267 208.825866a305.68702 305.68702 0 0 1 191.99436 67.198026l151.771542-148.411641A527.536504 527.536504 0 0 0 53.758421 284.151653l175.418847 131.356141a306.774988 306.774988 0 0 1 292.535407-206.713927"
        fill="#EA4335"
        p-id="5412"
      ></path>
      <path
        d="M521.87267 815.30405a307.126978 307.126978 0 0 1-292.631404-206.713927l-175.514844 131.324142a521.136692 521.136692 0 0 0 468.146248 284.023657 503.121221 503.121221 0 0 0 339.830017-127.100267l-166.7791-126.108295a327.894368 327.894368 0 0 1-173.210912 44.57469"
        fill="#34A853"
        p-id="5413"
      ></path>
      <path
        d="M1023.457936 513.744909a415.66779 415.66779 0 0 0-11.90365-92.669278H525.296569v197.018212h279.735783a229.561257 229.561257 0 0 1-106.524871 152.411523l166.619106 126.140295a502.033253 502.033253 0 0 0 158.075356-382.900752"
        fill="#4285F4"
        p-id="5414"
      ></path>
    </svg>
  );
}

function FaceBookSvg() {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="6992"
      width="26"
      height="26"
    >
      <path
        d="M546.986667 1024H56.490667A56.533333 56.533333 0 0 1 0 967.466667V56.533333C0 25.301333 25.301333 0 56.533333 0h910.933334C998.698667 0 1024 25.301333 1024 56.533333v910.933334c0 31.232-25.301333 56.533333-56.533333 56.533333H706.56v-396.544h133.12l19.882667-154.538667H706.56V374.186667c0-44.714667 12.373333-75.221333 76.586667-75.221334h81.834666v-138.24c-14.165333-1.92-62.72-6.101333-119.253333-6.101333-118.016 0-198.784 72.021333-198.784 204.288v113.92H413.525333v154.581333h133.418667V1024z"
        fill="#3B5998"
        p-id="6993"
      ></path>
    </svg>
  );
}

const redirect_uri = process.env.NEXT_PUBLIC_SITE_URL + '/';
const providersSvg: { [key in Provider]: JSX.Element } = {
  google: <GoogleSvg />,
  facebook: <FaceBookSvg />,
};

const LoginForm = () => {
  const { updateLoginModal, setUserLoginInfo } = useUserStore();
  let get_form_info: any = getCookie("created__user__info");
  if (get_form_info) {
    get_form_info = JSON.parse(get_form_info);
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormValues>();

  const [loading, setLoading] = useState(false);
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);
    await axios
      .post("/api/auth/login", data)
      .then((response) => {
        if (response?.data?.status === 400) {
          toast.error("You are not allowed.Because you are not a customer");

          setLoading(false);
        } else {
          toast.success("Login Successfully");
          setUserLoginInfo(data as UserLoginInfo);
          if (!getCookie("__user__login__info")) {
            const logInfo = {
              username: response.data?.data?.display_name,
              email: response.data?.data?.email,
              id: response.data?.data?.user_id,
              role: response.data?.data?.role,
              avatar: response.data?.data?.avatar_urls,
              password: data.password,
            };
            console.log(logInfo);
            if (data?.remember != false) {
              setCookie("__user__login__info", logInfo, { maxAge: 60 * 60 * 24 * 7 }); // 7 days
            } else {
              setCookie("__user__login__info", logInfo);
            }
          }
          setLoading(false);
          reset();
          updateLoginModal(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const [loginProviders, setLoginProviders] = useState<Provider[]>(["facebook", "google"]);
  useEffect(() => {
    axios
      .get(process.env.NEXT_PUBLIC_WORDPRESS_URL + "/wp-json/wslu/v1/providers")
      .then((res) => {
        const data = res.data?.data?.providers || {};
        const list = Object.keys(data)?.filter((key: any) => data[key]?.enable) as Provider[];

        setLoginProviders(list);
      })
      .catch((err) => {});
  }, []);

  const sid = useRef<string>();
  const handleThirdLogin = async (type: Provider) => {
    try {
      const loginWindow = window.open("", "loginPopup", `width=800,height=600`);
      if (!loginWindow) {
        throw new Error("Failed to request the third-party login");
      }

      sid.current = nanoid(32);
      const res = await axios.get<{ data: { sid: string; url: string } }>(
        `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/wslu/v1/authorize-uri/${type}?redirect_uri=${redirect_uri}&sid=${sid.current}`
      );

      loginWindow.location.href = res.data.data.url;
      const timer = setInterval(() => {
        try {
          const url = loginWindow?.location?.href;
          if (url?.includes("?code=") || url?.includes("?state=")) {
            const params = new URL(url).searchParams;
            const code = params.get("code");
            const state = params.get("state");
            axios
              .get(
                `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/wslu/v1/token/${type}?state=${state}&code=${code}&sid=${sid.current}&redirect_uri=${redirect_uri}`
              )
              .then((res) => {
                toast.success("Login Successfully");
                const user = res.data.data.user;
                console.log("🚀 ~ .then ~ user:", res.data.data.token)
                const logInfo = {
                  username: user?.display_name,
                  email: user?.email,
                  id: user?.user_id,
                  role: user?.role,
                  avatar: user?.avatar_urls,
                  extras: user?.extras,
                  token: res.data.data.token
                };
                if (getValues("remember")) {
                  setCookie("__user__login__info", logInfo, { maxAge: 60 * 60 * 24 * 7 }); // 7 days
                } else {
                  setCookie("__user__login__info", logInfo);
                }
                updateLoginModal(false);
              })
              .catch((error) => {
                console.log(error);
                toast.error(error?.message);
              });

            clearInterval(timer);
            loginWindow?.close();
          }
        } catch (error: any) {
          console.log(error?.message);
        }
      }, 500);

      loginWindow.onclose = () => {
        clearInterval(timer);
      };
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <div>
      <div className="mt-7">
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            id="email"
            className={`px-5 py-3 rounded-lg outline-none border bg-white text-xl  placeholder:text-lg w-full ${
              errors.username ? "border-red-500" : "border-themeSecondary300"
            }`}
            defaultValue={get_form_info?.username}
            placeholder="Username or Email"
            {...register("username", { required: true })}
          />
          <input
            type="password"
            id="password"
            className={`px-5 py-3 rounded-lg outline-none border bg-white text-xl  placeholder:text-lg w-full mt-6 ${
              errors.password ? "border-red-500" : "border-themeSecondary300"
            }`}
            placeholder="Password"
            {...register("password", { required: true })}
          />
          <div className="my-6 flex items-center justify-between">
            <label className="text-base cursor-pointer">
              <input type="checkbox" className="w-4 h-4 cursor-pointer" id="remember" {...register("remember")} />
              <span className=" ml-2"> Remember me</span>
            </label>
            <Link href={"/auth/recover"} onClick={()=>{updateLoginModal(false);}}>
              <BodyText size="md" className=" text-themePrimary600 cursor-pointer">
                Forgot Password?
              </BodyText>
            </Link>
          </div>
          <Button size="lg" className={`flex gap-4 items-center justify-center w-full mt-6`}>
            {loading ? <RingLoader color="#fff" size={30} /> : ""}
            {loading ? "Processing..." : "Sign in"}
          </Button>
          <p className="text-sm mt-1 text-[#666]">
            By continuing to use, you agree to abide by Nextorch{"'"}s <a onClick={() => updateLoginModal(false)} className="text-black hover:text-main" href="/service-support/policies?key=privacyPolicy">Privacy Policy</a> and <a className="text-black hover:text-main" onClick={() => updateLoginModal(false)} href="/service-support/policies?key=termsOfUse">Terms of Use</a>.
          </p>
        </form>

        {loginProviders.length ? (
          <div className="flex items-center gap-4 mt-4">
            Other Login Methods:
            {loginProviders.map((item) => (
              <div key={item} className=" cursor-pointer" onClick={() => handleThirdLogin(item)}>
                {providersSvg[item]}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoginForm;
