// import React from "react";
// import { clsx } from "clsx";

// function CountBadge({ count = 0, className = "" }) {
//   return (
//     <>
//       {count > 0 && (
//         <span
//           className={clsx(
//             `absolute -top-full -right-[60%] p-0.5 bg-[#f44336] rounded-full text-white
//                                     text-[12px] inline-flex items-center px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ring-gray-500/10`,
//             className
//           )}
//         >
//           {count > 99 ? "99+" : count}
//         </span>
//       )}
//     </>
//   );
// }

// export default React.memo(CountBadge);
import { cn } from "@/lib/utils";
import React, { HTMLAttributes, ReactNode } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  count: number;
  className?: string;
}

export default function CountBadge(props: Props) {
  return (
    <div {...props} className={cn("relative", props.className)}>
      {props.children}
      {props.count > 0 && (
        <div className="bg-[#f44336] text-white w-5 h-5 rounded-full flex items-center justify-center text-xs absolute right-0 top-0 -translate-y-1/2 translate-x-1/2">
          {props.count > 99 ? "99+" : props.count}
        </div>
      )}
    </div>
  );
}
