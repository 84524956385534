import { create } from "zustand";
import { persist } from "zustand/middleware";

type LanguageState = {
  languageList: LanguageListInterface[];
  setLanguageList: (list: LanguageListInterface[]) => void;
  language: string;
  setLanguage: (language: string) => void;
  loadState: boolean;
  setSLoadState: (state: boolean) => void;
  showLanguage: boolean;
  setShowLanguage: (showLanguage: boolean) => void;
  getCurrentLanguage: () => LanguageListInterface | undefined;
};

export interface LanguageListInterface {
  id: string;
  code: string;
  name: string;
  icon: string;
}

export const useLanguageStore = create(
  persist<LanguageState>(
    (set, get) => ({
      languageList: [],
      language: "",
      loadState: false,
      showLanguage: false,
      setShowLanguage: (showLanguage) => {
        set({ showLanguage });
      },
      setLanguageList: (list) => {
        set({ languageList: list });
      },
      setLanguage: (language) => {
        set({ language });
      },
      setSLoadState: (loadState) => {
        set({ loadState });
      },
      getCurrentLanguage: () => {
        return get().languageList.find((item) => item.id === get().language);
      }
    }),
    {
      name: "language",
      version: 1,
      partialize: (state: LanguageState): any => ({
        languageList: state.languageList,
        language: state.language
      })
    }
  )
);