import {DocumentNode, gql} from "@apollo/client";

// 根据关键词搜索产品
const PRODUCTS_SEARCH:DocumentNode = gql`
query GetProducts($search: String!, $size: Int!, $cursor: String) {
  products(
    first: $size
    after: $cursor
    where: {search: $search, orderby: {field: NAME, order: DESC}}
  ) {
    pageInfo {
      hasNextPage
      endCursor
      offsetPagination {
        total
      }
    }
    edges {
      cursor
      node {
        databaseId
        name
        slug
        image {
          sourceUrl
        }
        ... on SimpleProduct {
          price
        }
        ... on VariableProduct {
          price
        }
      }
    }
  }
} 
`
export default PRODUCTS_SEARCH;
