"use client";

import React, { useRef, useState } from "react";
import Link from "next/link";
import useMobile from "@/lib/hooks/useMobile";
import { cn } from "@/lib/utils";
import Translate from "./Translate";
import axios from "axios";
import { Button } from "./ui/button";
import Image from "next/image";
import { useLanguageStore } from "@/store/language.store";
import { useProductStore } from "@/store/product.store";

// 提交询盘表单
export const sendInquiryForm = async (formValue: { [key: string]: any }) => {
  try {
    const res = await axios.post<{
      data: {
        code: number;
        success: boolean;
        msg: string;
      };
    }>(`/api/inquiry/send-inquiry`, formValue);
    return Promise.resolve(res.data.data);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      return Promise.reject(e.response?.data);
    }
  }
};

const menus = [
  // {
  //   name: "OUICK LINKS",
  //   items: [
  //     { name: "Home", link: "/" },
  //     { name: "Products", link: "/product" },
  //     { name: "Explore The Excitement", link: "/explore-the-excitement/about-us" },
  //     { name: "Service And Support", link: "/" },
  //   ],
  // },
  // {
  //   name: "PRODUCTS",
  //   items: [
  //     { name: "Illumination", link: "/" },
  //     { name: "Lighting Accessories", link: "/" },
  //     { name: "Holsters & Mounts", link: "/" },
  //     { name: "NEX Batons", link: "/" },
  //     { name: "NEX Baton Accessories", link: "/" },
  //     { name: "Multi-tools", link: "/" },
  //   ],
  // },
  {
    name: "SERVICE AND SUPPORT",
    items: [
      { name: "PRODUCT REGISTRATION", link: "/service-support/product-registration" },
      { name: "AFTER SALES SERVICE", link: "/service-support/after-sales-service" },
      { name: "DOWNLOAD", link: "/service-support/download" },
      { name: "FQA", link: "/service-support/faq" },
      { name: "AFTER SALES POLICY", link: "/service-support/after-sales-policy" },
      { name: "CONTACT US", link: "/service-support/contact-us" },
      { name: "WHERE TO BUY", link: "/service-support/where-to-buy" },
      { name: "TERMS OF USE", link: "/service-support/policies?key=termsOfUse" },
      { name: "PRIVACY POLICY", link: "/service-support/policies?key=privacyPolicy" },
    ],
  },
];

function Shop() {
  return (
    <section className=" container text-white text-center py-[50px] space-y-4">
      <h4 className=" font-semibold md:text-3xl text-2xl text-balance">Shop With NEXTORCH Store</h4>
      <p className="text-[rgba(255,255,255,.85)] text-sm font-medium">
        Advanced manufacturer of portable lighting products.
      </p>
      <Button size="sm">
        <Link href={"/store"} className="px-4">
          SHOP NOW
        </Link>
      </Button>
    </section>
  );
}

export default function Footer() {
  const isMobile = useMobile();

  const [showEmailTips, setShowEmailTips] = useState("");
  const inputValue = useRef("");

  const [loading, setLoading] = useState(false);

  const { parentCategory } = useProductStore();

  const handleSend = async () => {
    if (inputValue.current.match(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/)) {
      setShowEmailTips("");

      try {
        if (loading) return;

        setLoading(true);
        const result = await sendInquiryForm({
          inquiry_info: {
            email: inputValue.current,
          },
          goods: [{ id: 0, count: 0 }],
          source: 1,
        });
        if (!!result) {
          setShowEmailTips("Successfully sent, please wait for the reply.");
        }
      } catch (error) {
        setShowEmailTips("Failed to send, please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      setShowEmailTips("Please enter a valid email address.");
    }
  };

  const menuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [showMenu, setShowMenu] = useState("");
  const handleClickMenu = (name: string) => {
    if (!isMobile) return;

    const el = menuRefs.current[name];
    
    if (el) {
      if (showMenu === name) {
        setShowMenu("");

        el.style.height = "1px";
        el.style.padding = "0";
      } else {
        setShowMenu(name);

        el.style.height = "auto";
        // const { height } = el.getBoundingClientRect();
        // el.style.height = "0px";
        // el.offsetHeight; //
        // el.style.height = `${height}px`;
        el.style.padding = "16px";
      }
    }
  };

  const { languageList, language } = useLanguageStore();

  return (
    <footer className="bg-[#272727] text-white overflow-hidden">
      <Shop />

      <div className=" container border-t border-[rgba(255,255,255,.12)] grid md:grid-cols-2 md:gap-y-5 lg:grid-cols-4 grid-cols-1 py-[50px] text-[rgba(255,255,255,.65)]">
        <div className="flex flex-col gap-3 max-md:border-b border-[#434343] max-md:pb-5">
          <Link href="/" className="w-[200px]">
            <Image src="/logo-white.png" width={585} height={93} alt="logo" />
          </Link>
          {/* <h2 className="text-white text-3xl font-semibold">
            NEX<span className="text-main">TORCH</span>
          </h2> */}
          <p className="text-[13px] md:pr-8 pt-2">
            NEXTORCH is one of the world’s leading manufacturers of illumination tools and specialized equipment for
            military, law enforcement, first responder, tactical, and outdoor applications.
          </p>

          <h6 className="text-white">SUBSCRIBE</h6>
          <p className="text-sm">Get the latest news from NEXTORCH.</p>
          <div className="md:pr-12 flex">
            <input
              type="email"
              placeholder="Your email address"
              className="px-3 py-1 max-md:flex-1 rounded bg-[#272727] border border-[#494b4d] outline-none placeholder:text-sm placeholder:text-[rgba(255,255,255,.65)]"
              onChange={(v) => (inputValue.current = v.target.value)}
            />
            <i className="ri-mail-send-line bg-main py-1 px-3 rounded cursor-pointer" onClick={handleSend}></i>
          </div>
          {showEmailTips && <span className="text-xs">{showEmailTips}</span>}
          <div className="flex items-center gap-4">
            <Link href="https://www.facebook.com/nextorchlight" target="_blank" className="c-flex cursor-pointer">
              <Image src="/image/media/facebook.png" width={25} height={25} alt={"facebook"} />
            </Link>
            <Link
              href="https://www.instagram.com/nextorch_worldwide/"
              target="_blank"
              className="c-flex cursor-pointer"
            >
              <Image src="/image/media/ins.png" width={25} height={25} alt="linkedin" />
            </Link>
            <Link href="https://www.tiktok.com/@nextorch.ltd" target="_blank" className="c-flex cursor-pointer">
              <Image src="/image/media/tiktok.png" width={25} height={25} alt="x.com" />
            </Link>

            <Link
              href="https://www.youtube.com/@nextorch/videos"
              target="_blank"
              className="c-flex bg-red-600 rounded-full w-[25px] h-[25px] cursor-pointer"
            >
              <Image src="/image/media/youtube.png" width={18} height={18} alt="youtube" />
            </Link>
          </div>
        </div>

        <div className="flex flex-col md:gap-3">
          <h4
            className={cn("text-base text-white", isMobile ? "py-4 flex justify-between" : "")}
            onClick={() => handleClickMenu("PRODUCTS")}
          >
            PRODUCTS
            <i className="ri-add-line md:hidden"></i>
          </h4>
          <div
            ref={(el) => {
              menuRefs.current["PRODUCTS"] = el;
            }}
            className={cn(
              "flex flex-col gap-3 max-md:bg-[#434343] duration-300 overflow-hidden",
              isMobile ? "h-[1px]" : ""
            )}
          >
            {(parentCategory ?? []).map((i) => {
              return (
                <Link href={`/${i.slug}`} key={i.slug} className="text-sm hover:text-white">
                  {i.name}
                </Link>
              );
            })}
          </div>
        </div>

        {menus.map((item) => (
          <div className="flex flex-col md:gap-3" key={item.name}>
            <h4
              className={cn("text-base text-white", isMobile ? "py-4 flex justify-between" : "")}
              onClick={() => handleClickMenu(item.name)}
            >
              {item.name}
              <i className="ri-add-line md:hidden"></i>
            </h4>
            <div
              ref={(el) => {
                menuRefs.current[item.name] = el;
              }}
              className={cn(
                "flex flex-col gap-3 max-md:bg-[#434343] duration-300 overflow-hidden",
                isMobile ? "h-[1px]" : ""
              )}
            >
              {item.items.map((linkInfo) => (
                <Link href={linkInfo.link} key={linkInfo.name} className="text-sm hover:text-white">
                  {linkInfo.name}
                </Link>
              ))}
            </div>
          </div>
        ))}

        <div className="flex flex-col gap-3 max-md:mt-5">
          <h4 className="text-base text-white">CONTACT US</h4>
          <Link target="_blank" href={`tel:+862089047029`} className="text-base text-main font-semibold">+86-20-8904 7029(7070)</Link>
          <Link target="_blank" href={`mailto:info@nextorch.com`} className="text-sm hover:text-white">Email: info@nextorch.com</Link>
          <p className="text-sm hover:text-white">Fax: +86-20-8904 70039</p>
          <Link target="_blank" href={`https://www.google.com/maps/search/Suite 12303, North Tower, No.1068 XinGangDong Road, Haizhu District, Guangzhou, Guangdong, China 510335`} className="text-sm hover:text-white">Add: Suite 12303, North Tower, No.1068 </Link>
          <Link target="_blank" href={`https://www.google.com/maps/search/Suite 12303, North Tower, No.1068 XinGangDong Road, Haizhu District, Guangzhou, Guangdong, China 510335`} className="text-sm hover:text-white">XinGangDong Road, Haizhu District, </Link>
          <Link target="_blank" href={`https://www.google.com/maps/search/Suite 12303, North Tower, No.1068 XinGangDong Road, Haizhu District, Guangzhou, Guangdong, China 510335`} className="text-sm hover:text-white">Guangzhou, Guangdong, China 510335</Link>
          <div className="mt-4">
            <Translate>
              <div className=" cursor-pointer flex items-center gap-2">
                <i className="ri-global-line ri-xl"></i>
                {languageList.find((item) => item.id === language)?.name}
              </div>
            </Translate>
          </div>
        </div>
      </div>

      <div className="py-4 text-center border-t border-[rgba(255,255,255,.12)] text-xs text-[rgba(255,255,255,.65)] font-medium">
       <a href="https://beian.miit.gov.cn/#/Integrated/index" className="hover:text-main">© 2024 Guangdong Nalide Mobile Lighting Co., Ltd. All rights reserved. Yue ICP Bei 13030313-4</a>
      </div>
    </footer>
  );
}
