"use client";

import { ChangeEvent, useEffect, useState } from "react";
import { debounce, setOverflow } from "@/lib/utils";
import Link from "next/link";
import { RingLoader } from "react-spinners";
import client from "@/lib/ApolloClient/apolloClient";
import PRODUCTS_SEARCH from "@/lib/queries/by-keyword-search-product";
import { GqlSearchProductInterface, SearchProductEdge } from "@/lib/types/gql/product/search-product.type";
import EmptyState from "@/components/EmptyState";
import Price from "@/components/Price/price";
import { HEADER_POST_SEARCH } from "@/lib/queries/post-by-name";
import { GqlHeaderSearchPostInterface } from "@/lib/types/gql/post/header-post-search.type";
import { BodyText } from "@/components/BodyText";
import { GqlPostsNodeInterface } from "@/lib/types/gql/post/post.type";
import { toast } from "sonner";
import Placeholder from "./Placeholder";
import { Button } from "./ui/button";
import { usePathname } from "next/navigation";

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
};

const pageSize = 4;

const ShowSeeBtn = ({ total, link = "/", className = "" }: { total: number; link: string; className?: string }) => {
  return (
    <div className={`${className} rounded-md my-6`}>
      {total > pageSize ? (
        <Link href={link}>
          <Button>SEE ALL {total} RESULTS</Button>
        </Link>
      ) : (
        ""
      )}
    </div>
  );
};

const ProductItem = ({ products, total = 0,searchval }: { products: SearchProductEdge[]; total: number,searchval: string }) => {
  return (
    <>
      <h2 className="text-xl pb-2">Products</h2>
      <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4">
        {products.map((p) => {
          const node = p.node;
          return (
            <Link
              href={`/product/${node.slug}`}
              key={node.databaseId}
              className="group relative shadow p-4 max-md:p-2 s-flex flex-raw md:flex-col !items-start"
            >
              <div className="overflow-hidden rounded-md group-hover:opacity-75 max-md:w-[60px] max-md:h-[60px]">
                <Placeholder src={node?.image?.sourceUrl || ""} quality={50} width={500} height={500} alt={node.name} />
              </div>
              <div className="mt-4 flex flex-col justify-between max-md:mt-0 max-md:ml-4">
                <div>
                  <h3 className="text-sm font-bold text-gray-700 line-clamp-2">{node.name}</h3>
                </div>
                <p className="text-sm font-medium text-gray-900">
                  <Price price={node.price} />
                </p>
              </div>
            </Link>
          );
        })}
      </div>
      <ShowSeeBtn total={total} link={`/product?search=${searchval}`} />
    </>
  );
};

const PostItem = ({ posts, total = 0, searchval}: { posts: GqlPostsNodeInterface[]; total: number, searchval: string }) => {
  return (
    <>
      <h2 className="text-xl pb-2">Posts</h2>
      <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4">
        {posts.map((p) => {
          const node = p;
          return (
            <Link
              href={`/explore-the-excitement/blog/${node.slug}`}
              key={node.databaseId}
              className="group relative shadow p-4 max-md:p-2 s-flex flex-raw md:flex-col !items-start"
            >
              <div className="flex-1 overflow-hidden rounded-md group-hover:opacity-75 max-md:w-[60px] max-md:h-[60px]">
                <Placeholder
                  src={node.featuredImage?.node?.sourceUrl}
                  quality={50}
                  width={500}
                  height={500}
                  alt={node.title}
                  className=" object-contain"
                />
              </div>
              <div className="mt-4 max-md:mt-0 max-md:ml-2 flex justify-between flex-col gap-2">
                <h3 className="text-sm text-gray-700 font-bold line-clamp-2">{node.title}</h3>
                <BodyText innerHTML={node.excerpt} fontFamily={"SGL"} className="line-clamp-2 max-md:hidden"></BodyText>
                <p className="text-gray-500 pt-1">{new Date(node.date).toLocaleDateString()}</p>
              </div>
            </Link>
          );
        })}
      </div>
      <ShowSeeBtn total={total} link={`/explore-the-excitement/blog/search/${searchval}`} />
    </>
  );
};

export default function Search({ open = false, setOpen }: Props) {
  const pathname = usePathname();
  const [show, setShow] = useState(open);

  const [searchLoading, setSearchLoading] = useState(false);

  const [products, setProducts] = useState<GqlSearchProductInterface | null>(null);

  const [posts, setPosts] = useState<GqlHeaderSearchPostInterface | null>(null);
  const [searchValue,setSearchValue] = useState<string>("")
  const handlerInput = debounce(async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) return;
    setSearchValue(value)
    try {
      setSearchLoading(!!value);
      const promise = [searchProduct(value), searchPost(value)];
      await Promise.all(promise);
    } catch (e: any) {
      toast.error(e?.message);
    } finally {
      setSearchLoading(false);
    }
  }, 300);

  const searchProduct = async (value = "") => {
    try {
      const requestId = "search-product";
      client.abortRequest(requestId);
      const { data } = await client.query<GqlSearchProductInterface>({
        query: PRODUCTS_SEARCH,
        variables: {
          search: value,
          size: pageSize,
          cursor: null,
        },
        requestId,
      });
      data && setProducts(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  const searchPost = async (value = "") => {
    try {
      const requestId = "header-post-search";
      client.abortRequest(requestId);
      const { data } = await client.query<GqlHeaderSearchPostInterface>({
        query: HEADER_POST_SEARCH,
        variables: {
          search: value,
          size: pageSize,
          cursor: null,
        },
      });
      data && setPosts(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    setShow(open);
    if (open) {
      setOverflow("hidden");
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setOpen(false);
        }
      });
    } else {
      setOverflow("");
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setOpen(false);
        }
      });
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <>
      {show ? (
        <div className={`absolute top-full right-0 z-[2] left-0 border-b-[1px] shadow h-[100vh]`}>
          <div className="relative z-[2] bg-white text-black h-[90vh] md:h-[60vh] overflow-y-auto">
            <div className="max-w-screen-lg mx-auto flex flex-col max-md:px-6">
              <div className="e-flex">
                <i className="ri-close-line ri-2x cursor-pointer" onClick={() => setOpen(false)}></i>
              </div>
              <div className="w-full my-3 border-b-[1px] border-black px-2 s-flex">
                <i className="ri-search-line ri-2x"></i>
                <input
                  type="text"
                  className="outline-0 ml-4 w-full"
                  placeholder="Search Product or Posts"
                  autoFocus={true}
                  onInput={handlerInput}
                />
              </div>
              <div className="py-6">
                {searchLoading ? (
                  <div className="c-flex">
                    <RingLoader color="#000" />
                  </div>
                ) : !!products?.products?.edges?.length || !!posts?.posts?.nodes?.length ? (
                  ""
                ) : (
                  <EmptyState text={"NO RESULT"} />
                )}
              </div>
              {products?.products?.edges && !!products?.products?.edges.length && (
                <div className="pb-6">
                  <ProductItem
                    products={products.products.edges}
                    total={products?.products?.pageInfo?.offsetPagination.total}
                    searchval={searchValue}
                  />
                </div>
              )}
              {posts?.posts?.nodes && !!posts?.posts?.nodes.length && (
                <div className="pb-6">
                  <PostItem posts={posts.posts?.nodes} total={posts?.posts?.pageInfo?.offsetPagination.total} searchval={searchValue}/>
                </div>
              )}
            </div>
          </div>
          <div
            className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.33)] z-[1]"
            onClick={() => setOpen(false)}
          ></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
