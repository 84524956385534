import axios from "axios";
import { deleteCookie } from "cookies-next";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { toast } from "sonner";

export function useCocartLogout() {
  const router = useRouter();
  const [isLogout, setIsLogout] = useState(false);

  async function logout() {
    try {
      console.log(1111);

      if (isLogout) return;
      setIsLogout(true);
      const res = await axios.post(`${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/cocart/v2/logout`);
      if (res.data) {
        deleteCookie("__user__login__info");
        router.push("/");
        toast.success("Your are successfully logout!");
      }
    } catch (error: any) {
      toast.error("Something is wrong!");
    } finally {
      setIsLogout(false);
    }
  }

  return {
    logout,
  };
}
