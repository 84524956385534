import { useState } from "react";
import { getCookie, setCookie } from "cookies-next";
import axios from "axios";
import { toast } from "sonner";

export function useCreateUser() {
  const [loading, setLoading] = useState(false);

  function createUser(data: any, reset: any, setActive: any) {
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoading(true);
    axios
      .post("/api/auth/register", data)
      .then((response) => {
        toast.success("Account Created Successfully");
        setLoading(false);
        toast.success("Coupon sent to personal account");
        reset();
        if (!getCookie("signup__user__info")) {
          const userInfo = {
            username: response.data.username,
            email: response.data.email,
            id: response.data.id,
          };
          setCookie("created__user__info", userInfo);
        }
        setTimeout(() => {
          setActive(1);
        }, 1000);
      })
      .catch((error) => {
        if (!error?.response?.data?.code) {
          toast.error("Something went wrong");
          return;
        }
        if (
          error.response.data.code == "registration-error-email-exists" ||
          error.response.data.code == "registration-error-username-exists"
        ) {
          toast.error("Email or Username already exists");
        }
        if (error.response.data.code == "customer_invalid_email") {
          toast.error("Invalid Email");
        }
        if (error.response.data.code === "service-error") {
          toast.error("Invalid Email");
        }
        setLoading(false);
      });
  }

  return { createUser, loading };
}
