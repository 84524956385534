"use client";

import { useUserStore } from "@/store/user.store";
import LoginForm from "./Form/User/login-form";
import SignUpForm from "./Form/User/sign-up-form";

type SignSwitchProps = {
  activeId: number;
  setActiveId: (id: number) => void;
};

export default function SignSwitch({ activeId = 1, setActiveId }: SignSwitchProps) {
  const list = [
    {
      id: 1,
      label: "Log In",
      description: "Enter your login information",
      component: <LoginForm />,
    },
    {
      id: 2,
      label: "Register",
      description: "Enter your credential information",
      component: <SignUpForm setActive={setActiveId} />,
    },
  ];
  return (
    <div className="z-50 w-full">
      <div className="c-flex relative">
        {list.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => setActiveId(item.id)}
              className={`border-b-[2px] px-8 ${activeId === item.id ? "border-themePrimary600" : ""}`}
            >
              <div
                className={`text-center font-medium ${
                  activeId === item.id ? "text-themePrimary600" : ""
                } cursor-pointer py-4`}
              >
                {item.label}
              </div>
            </div>
          );
        })}
      </div>

      {activeId === 1 && (
        <div className="mt-7">
          {/* <h3 className="text-xl md:text-2xl sm:leading-8 font-PM text-center text-themeSecondary800">
            {list[activeId - 1].label}
          </h3> */}
          {/* <div className="text-center text-themeSecondary500 mt-2">{list[activeId - 1].description}
            <span className="ml-1 underline cursor-pointer" onClick={() => setActiveId(2)}>Register</span>
          </div> */}
          {list[activeId - 1].component}
        </div>
      )}

      {activeId === 2 && (
        <div className="mt-7  h-[calc(100vh-200px)] md:h-auto lg:max-h-[85vh] overflow-y-auto">{/* 针对移动端和PC端设置不同高度 */}
          <div className="z-1 w-full overflow-hidden">
            <div className="relative h-full w-full ">
              <img src="/image/coupon.webp" alt="" className="h-full w-full object-cover" />
              <div className="text-themeSecondary800 absolute left-0 top-10 w-full text-center text-xl font-semibold md:text-2xl">
                Sign Up for Exclusive Offers
              </div>
            </div>
          </div>
          {/* <h3 className="text-xl md:text-2xl mt-[200px] sm:leading-8 font-PM text-center text-themeSecondary800">Sign Up</h3> */}
          <div className=" text-center text-themeSecondary500">{/* {list[activeId - 1].description} */}</div>
          <SignUpForm setActive={setActiveId} />
        </div>
      )}
    </div>
  );
}
