import { create } from "zustand";
import { persist } from "zustand/middleware";
import { getSiteTypeIsTob } from "@/lib/utils";
import { GqlProductCategoriesParentNode2Interface } from "@/lib/types/gql/product/product-categories-parent.type";

type SetStoreState<T> = (
  partial: T | Partial<T> | ((state: T) => T | Partial<T>),
  replace?: boolean | undefined
) => void;

const DEFAULT_ACCESS_STATE = {
  parentCategory: [] as GqlProductCategoriesParentNode2Interface[] | null,
  currencyUnit: "$", // 货币初始单位
  recentlyViewed: [] as string[],
};

const state = (set: SetStoreState<typeof DEFAULT_ACCESS_STATE>, get: () => typeof DEFAULT_ACCESS_STATE) => ({
  ...DEFAULT_ACCESS_STATE,
  updateParentCategory: (parentCategory: GqlProductCategoriesParentNode2Interface[] | null) => {
    set({ parentCategory });
  },
  setCurrencyUnit: (unit: string) => set({ currencyUnit: unit }), // 更新货币单位的方法
  setRecentlyViewed: (id: string) => {
    const old = get().recentlyViewed;
    if (old.length >= 4) {
      return set({ recentlyViewed: [...new Set([id, ...old.slice(0, 3)])] });
    }
    return set({ recentlyViewed: [...new Set([...get().recentlyViewed, id])] });
  },
  setAllRecentlyViewed: (ids: string[]) => {
    return set({ recentlyViewed: ids });
  },
});

export const useProductStore = create(
  persist(state, {
    name: "productStore",
  })
);
