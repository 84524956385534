"use client";

import { Menu } from "@/lib/constants/menus";

import Link from "next/link";
import { Button } from "./ui/button";
import { useUserStore } from "@/store/user.store";
import { cn, setOverflow } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";

function Login({ open, onOpenChange }: { open: boolean; onOpenChange: (is: boolean) => void }) {
  const { userInfo, updateLoginModal } = useUserStore();

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true); // This will run only on the client side
  }, []);

  if (!isClient) {
    // Prevent server-side rendering of the button
    return null;
  }

  return (
    !userInfo && (
      <>
        <Button
        className={cn("mt-auto", open ? "translate-y-0 opacity-100" : "translate-y-[-20px] opacity-0")}
        onClick={() => {
          onOpenChange(false);
          updateLoginModal(true);
        }}
      >
        Login
      </Button>
      </>
    )
  );
}

export default function MenuDrawer({
  open,
  onOpenChange,
  menu,
}: {
  menu: Menu[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  useEffect(() => {
    if (open) {
      setOverflow("hidden");
    } else {
      setOverflow("");
    }
  }, [open]);

  const [openLink, setOpenLink] = useState<number>();
  const refs = useRef<{ [key: number]: HTMLUListElement | null }>({});

  function handleClick(id: number) {
    if (openLink === id) {
      const el = refs.current[openLink];
      if (el) {
        el.style.height = "0";
      }
      setOpenLink(-1);
    } else {
      const el = refs.current[id];
      if (el) {
        el.style.height = "auto";
        const { height } = el.getBoundingClientRect();
        el.style.height = "0px";
        el.offsetHeight; // 触发重绘
        el.style.height = `${height}px`;
      }
      const el2 = refs.current[openLink ?? -1];
      if (el2) {
        el2.style.height = "0";
      }
      setOpenLink(id);
    }
  }
  const pathname = usePathname();

  useEffect(() => {
    setOpenLink(-1);
    onOpenChange(false);
  }, [pathname]);

  return (
    <div
      className={cn(
        "fixed inset-0 bg-white duration-300 overflow-hidden",
        open ? "top-[--nav-height]" : "top-[--nav-height] bottom-full"
      )}
    >
      <div className="flex flex-col p-7 pt-0 h-full">
        {menu.map((item, i) => (
          <div
            className={cn("py-3 duration-300", open ? "translate-y-0 opacity-100" : "translate-y-[-30px] opacity-0")}
            style={{ transitionDuration: 300 + i * 100 + "ms" }}
            key={item.id}
          >
            {item.children?.length ? (
              <div className="w-full overflow-hidden cursor-pointer">
                <div
                  className="flex items-center justify-between"
                  onClick={() => {
                    handleClick(item.id);
                  }}
                >
                  <span className="">{item.name}</span>
                  <i className="ri-arrow-down-s-line"></i>
                </div>

                <ul
                  ref={(el) => {
                    refs.current[item.id] = el;
                  }}
                  className="duration-300 h-0"
                >
                  {item.children?.map((child) => (
                    <li key={child.id} className="p-4 pb-0">
                      <Link href={child.link} className=" ">
                        {child.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <Link href={item.link}>{item.name}</Link>
            )}
          </div>
        ))}

        <Login open={open} onOpenChange={onOpenChange} />
      </div>
    </div>
  );
}
