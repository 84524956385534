"use client";

import { getPageCodeById } from "@/lib/utils";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";

const showTopAdv = ["/"];
const fetchData = async () => {
  // const res = await getPageCodeById("hX1c4QJ525nB9JDdjpNKp");
  // return res;
};

export default function TopAdv() {
  // const [data, setData] = useState<any>("");
  // useEffect(() => {
  //   fetchData().then((res) => {
  //     setData(res);
  //   });
  // }, []);
  const pathname = usePathname();

  return showTopAdv.includes(pathname) ? (
    <div className="container h-[48px] text-center text-xs font-medium !leading-[48px] max-md:hidden md:text-sm">
      {/* {data?.[0]?.content} */}
      Always at your service. Reach out at any time.
    </div>
  ) : null;
}
