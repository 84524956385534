import { useShoppingCartStore } from "@/store/shoppingCart.store";
import { useEffect, useState } from "react";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { usePathname } from "next/navigation";
import { CartContent } from "@/components/ShoppingCart/cartContent";
import { useCart } from "@/lib/hooks/cart/useCart";

export const CartDrawer = () => {
  const { isOpenCart, setIsOpenCart, cartList } = useShoppingCartStore();
  const { getCart, addToCart } = useCart();
  const [count, setCount] = useState(0);
  const pathname = usePathname();
  useEffect(() => {
    setCount(cartList?.item_count || 0);
  }, [cartList]);
  useEffect(() => {
    setIsOpenCart(false);
  }, [pathname]);
  useEffect(() => {
    getCart();
  }, []);
  return <Sheet open={isOpenCart} onOpenChange={(isOpen) => setIsOpenCart(isOpen)}>
    <SheetContent className="md:!w-[40vw] !w-[90vw] !max-w-[90vw] overflow-y-auto">
      <SheetHeader>
        <SheetTitle>Shopping cart</SheetTitle>
        <SheetDescription>
          {count} items
        </SheetDescription>
      </SheetHeader>
      <div className="my-4">
        <CartContent layout="col" />
      </div>
    </SheetContent>
  </Sheet>;
};
