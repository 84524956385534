"use client";

import { useEffect, useState } from "react";

export default function Topping() {
  const topPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    function onScroll() {
      if (window.scrollY > 200) {
        setShow(true);
      } else {
        setShow(false);
      }
    }

    onScroll();

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return show ? (
    <div className="fixed cursor-pointer max-md:hidden z-50" onClick={topPage}>
      <div className="w-[50px] h-[50px] flex justify-center items-center bg-white shadow-gray-500 shadow-md rounded-full fixed right-10 bottom-10">
        <i className="ri-skip-up-fill ri-2x text-main"></i>
      </div>
    </div>
  ) : null;
}
