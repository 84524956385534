import React from "react";
import { RingLoader } from "react-spinners";
import { useCreateUser } from "@/lib/hooks/user/useCreateUser";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/store/user.store";

type FormValues = {
  username?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  onSubmit: (data?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

interface SignUpFormProps {
  setActive: any;
}

const SignUpForm = ({ setActive }: SignUpFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  const { createUser, loading } = useCreateUser();
  const { updateLoginModal } = useUserStore()

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    createUser(data, reset, setActive);
  };

  return (
    <div>
      <div className=" mt-7">
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            id="username"
            className={`px-5 py-3 rounded-lg outline-none border bg-white text-xl  placeholder:text-lg w-full ${
              errors.username ? "border-red-500" : "border-themeSecondary300"
            }`}
            placeholder="Username"
            {...register("username", { required: true })}
          />
          <input
            type="email"
            id="email"
            className={`px-5 py-3 rounded-lg outline-none border bg-white text-xl  placeholder:text-lg w-full mt-6 ${
              errors.email ? "border-red-500" : "border-themeSecondary300"
            }`}
            placeholder="Email"
            {...register("email", { required: true })}
          />
          <input
            type="password"
            id="password"
            className={`px-5 py-3 rounded-lg outline-none border bg-white text-xl  placeholder:text-lg w-full mt-6 ${
              errors.password ? "border-red-500" : "border-themeSecondary300"
            }`}
            placeholder="Password"
            {...register("password", { required: true })}
          />
          <input
            type="password"
            id="confirmPassword"
            className={`px-5 py-3 rounded-lg outline-none border bg-white text-xl  placeholder:text-lg w-full mt-6 ${
              errors.confirmPassword ? "border-red-500" : "border-themeSecondary300"
            }`}
            placeholder="Confirm Password"
            {...register("confirmPassword", { required: true })}
          />
          <Button
            className={`flex gap-4 items-center justify-center w-full mt-6`}
          >
            {loading && <RingLoader color="#fff" size={30} />}
            {loading ? "Processing..." : "Sign Up"}
          </Button>
          <p className="text-sm mt-1 text-[#666]">
            By continuing to use, you agree to abide by Nextorch{"'"}s <a onClick={() => updateLoginModal(false)} className="text-black hover:text-main" href="/service-support/policies?key=privacyPolicy">Privacy Policy</a> and <a onClick={() => updateLoginModal(false)} className="text-black hover:text-main"  href="/service-support/policies?key=termsOfUse">Terms of Use</a>.
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
